

export const tradeAgeCalculator = (transactionDate, isMilliseconds = false) => {

    const date = new Date(); // Current UTC

    let dateDelta = 0;

    if (!transactionDate) return

    // Date coming from formatOpen Positions is already in ms time
    if (isMilliseconds) {
        dateDelta = date.getTime() - transactionDate;
    } else {
        // Need to add Z since our returned dates dont have a time zone specified
        const closeDateObj = transactionDate.endsWith("Z") ? new Date(transactionDate) : new Date(transactionDate + "Z");
    
        dateDelta = date.getTime() - closeDateObj.getTime();
    }

    const millisecondsHour = 3600000;
    const millisecondsDay = 86400000;
    let tradeAge = "";

    if (dateDelta < 60000) {
        tradeAge = "1m"
    } else if ( dateDelta < millisecondsHour) {
        tradeAge = `${Math.floor((dateDelta/millisecondsHour) * 60)}m`;
    } else if ( dateDelta < millisecondsDay ) {
        tradeAge =`${Math.floor((dateDelta/millisecondsDay) * 24)}h`;
    } else {
        tradeAge = `${Math.floor(dateDelta/millisecondsDay)}d`
    }

    return tradeAge;
}

export const tradeAgeCalculatorHours = (transactionDate, isMilliseconds = false) => {
    const date = new Date(); // Current UTC

    let dateDelta = 0;

    if (!transactionDate) return

    // Date coming from formatOpen Positions is already in ms time
    if (isMilliseconds) {
        dateDelta = date.getTime() - transactionDate;
    } else {
        // Need to add Z since our returned dates dont have a time zone specified
        const closeDateObj = transactionDate.endsWith("Z") ? new Date(transactionDate) : new Date(transactionDate + "Z");
    
        dateDelta = date.getTime() - closeDateObj.getTime();
    }

    const millisecondsDay = 86400000;

    const dateDeltaHours = (dateDelta / millisecondsDay) * 24;

    return dateDeltaHours;
}